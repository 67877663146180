import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

import jobboxOne from '../../assets/images/jobbox/new/jobbox1.webp';
import jobboxTwoA from '../../assets/images/jobbox/new/jobbox-2-a.webp';
import jobboxTwoB from '../../assets/images/jobbox/new/jobbox-2-b.webp';
import jobboxTwoC from '../../assets/images/jobbox/new/jobbox-2-c.webp';
import jobboxTwoD from '../../assets/images/jobbox/new/jobbox-2-d.webp';
import jobboxThreeA from '../../assets/images/jobbox/new/jobbox-3-a.webp';
import jobboxThreeB from '../../assets/images/jobbox/new/jobbox-3-b.webp';
import jobboxThreeC from '../../assets/images/jobbox/new/jobbox-3-c.webp';
import jobboxClientImage from '../../assets/images/jobbox/new/jobbox-client.webp';
import jobBox from '../../assets/images/jobbox/jobbox-banner.webp';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Jobbox extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section>
            <img className="case-study-banner" src={jobBox} alt="banner" />
          </section>
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  P2P Tool & Equipment Rental Application
                </div>
                <div className="subtitle section-margin">
                  Connecting renters with tool owners in their area through a simple, easy to use
                  mobile app{' '}
                </div>
                <div className="client-title section-margin">
                  CLIENT | JOB<span style={{color: '#E9B238'}}>BOX</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build a scalable technology solution that would allow owners to rent out idle
                      tools and equipment to others in their area.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed a minimum viable product (MVP) for both iOS and Android within three
                      months within a limited budget.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      The user-friendly marketplace app allowed JobBox to expand coverage to newer
                      service areas and successfully raise seed funding.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://startupsavant.com/startup-center/jobbox"
                      target="_blank" rel="noreferrer"
                    >
                      Jobbox - Startup Company Profile
                    </a>{' '}
                    - TRUiC
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.bizjournals.com/jacksonville/inno/stories/profiles/2021/12/14/meet-jobbox-the-construction-app-started-in-jacks.html"
                      target="_blank" rel="noreferrer"
                    >
                      Construction app Jobbox looking to build big future from Jacksonville roots
                    </a>{' '}
                    - Jacksonville Business Journal
                  </p>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/4CIazuU4THk"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="text-with-image-container section-margin">
                    <img
                      className="image-container text-center col-2"
                      src={jobboxOne}
                      alt="jobbox"
                    />
                    <div className="subheading-big">
                      Client&apos;s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://clinkit.app/"
                        style={{textDecoration: 'underline'}}
                        target="_blank" rel="noreferrer"
                      >
                        JobBox
                      </a>{' '}
                      was envisioned as a peer-to-peer (P2P) tool and equipment rental application
                      that would make renting necessary equipment easier than ever. Its founder,
                      Stephen Bradshaw, who operated a general contracting business in Jacksonville
                      back in 2019, came up with the idea when he failed to find a platform that
                      would allow him to make money renting out his idle tools and equipment to
                      others in his area.
                    </p>
                    <br />
                    <p className="description">
                      Stephen spent the next six months researching the rental market and creating
                      his business plan. After beta testing the concept by conducting manual P2P
                      transactions, he managed to get the validation he wanted to begin working on a
                      scalable solution.
                    </p>
                    <br />
                    <p className="description">
                      Lacking in tech experience or background, Stephen was looking for a technology
                      partner that could take charge of product development. Following meetings with
                      several technology vendors, he engaged Tintash owing to our vast experience of
                      working with early stage startups.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Stephen had clearly defined objectives when he first approached Tintash. He
                      had earmarked only a limited budget to develop a minimum viable product that
                      would allow him to gather user feedback. He wanted to use the simple app to
                      test his market assumptions and demonstrate the product concept and use cases
                      to potential investors before setting his final direction. improvements.
                    </p>
                    <br />
                    <p className="description">
                      Following initial meetings to understand Stephen’s vision and requirements,
                      our team comprising engineering managers and business analysts started
                      chalking out a product plan. To meet the business objectives, we consulted and
                      used as benchmark existing marketplace products. Prioritizing the feature set
                      essential to Stephen’s MVP requirements, we took him through a series of UI/UX
                      iterations.
                    </p>
                    <br />
                    <p className="description">
                      Within a few weeks, we had managed to put the wireframes in place. We also
                      finalized the technology stack at that point. React Native was an optimal
                      choice for the cross-platform application, together with a robust backend to
                      manage inventory and facilitate transactions.
                    </p>
                    <br />
                    <p className="description">
                      It took us three months to develop the MVP. Throughout the development phase,
                      we had weekly team meetings to review progress, assess the cash burn rate, and
                      prioritize what maximum could be built in our remaining runway. Following true
                      agile practices, we rapidly prototyped features, reviewed builds, and made
                      iterative
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="image-description text-center mt-5 mb-3 col-12">
                      JobBox: some in-app screens
                    </div>
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="col-lg-3 col-sm-9 my-3">
                        <img className="w-100 sub-pic" src={jobboxTwoA} alt="bed bath and beyond" />
                        <div className="image-description-small text-center my-3">
                          Select from a wide range of categories
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-9 my-3">
                        <img className="w-100 sub-pic" src={jobboxTwoB} alt="bed bath and beyond" />
                        <div className="image-description-small text-center my-3">
                          Find the best equipment quickly and based on your location
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-9 my-3">
                        <img className="w-100 sub-pic" src={jobboxTwoC} alt="bed bath and beyond" />
                        <div className="image-description-small text-center my-3">
                          Have any questions? Chat with the lender
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-9 my-3">
                        <img className="w-100 sub-pic" src={jobboxTwoD} alt="bed bath and beyond" />
                        <div className="image-description-small text-center my-3">
                          Choose the time that suits you best
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      The JobBox app was launched in November, 2020. Our team ensured all the
                      product targets were achieved within the defined budget and timeline. The app
                      was made available for both iOS and Android.
                    </p>
                    <br />
                    <p className="description">
                      The user-friendly app worked in simple steps. Users could start with creating
                      their profiles. The app used the addresses users entered to display local
                      inventory. Users could then quickly and easily upload their items and start
                      renting. The app included a suggested pricing feature to take the guesswork
                      out.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="col-lg-4 col-sm-9 my-3">
                        <img
                          className="w-100 sub-pic"
                          src={jobboxThreeA}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description-small text-center my-3">
                          Renters could easily find the right equipment
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-9 my-3">
                        <img
                          className="w-100 sub-pic"
                          src={jobboxThreeB}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description-small text-center my-3">
                          Suggested pricing built to help owners remain competitive
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-9 my-3">
                        <img
                          className="w-100 sub-pic"
                          src={jobboxThreeC}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description-small text-center my-3">
                          Owners could link items to inventory to increase rentals
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      The app was initially launched in two markets, Florida and Michigan, to gather
                      user feedback. After its initial success, JobBox later expanded coverage to
                      Texas as well. The growing traction of their product allowed Stephen and his
                      team to raise some seed funding from friends and family. At the time of this
                      writing, the team was seeking additional angel investment to close out their
                      $600k seed round and was planning to expand JobBox to additional markets.
                    </p>
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      Tintash just gets it. They could conceptualize my thoughts and build a roadmap
                      to get me to my desired results.
                    </p>
                    <div className="d-flex my-3">
                      <img src={jobboxClientImage} alt="Stephen Bradshaw" />

                      <div className="p-3 m-0 mt-auto mb-auto">
                        <h2 className="review-name m-0" style={{fontWeight: 'bold'}}>
                          Stephen Bradshaw
                        </h2>
                        <p className="review-name mt-0">
                          GM & Founder, JobBox on{' '}
                          <a
                            href="https://clutch.co/profile/tintash#review-1573904"
                            style={{textDecoration: 'underline'}}
                            target="_blank" rel="noreferrer"
                          >
                            Clutch
                          </a>
                        </p>
                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Jobbox;
